/** @jsx jsx */
import { jsx, Box, Card } from 'theme-ui'
import React from 'react'
import Accordian from '../../components/accordion'
import LineUp from '../../components/lineup'
import Layout from '../../components/layout'
import Hero from '../../components/hero'
import Versus from '../../components/versus'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { graphql } from 'gatsby'
import { Stack } from 'raam'
import Article, { Byline } from '../../components/article'
import { responsive } from '../../utils/helpers'
import Statistic from '../../components/statistic'
import Promo from '../../components/promo'
import MetaData from '../../components/metadata'

const moment = require('moment')

const MatchReport = props => {
  const page = props.data?.contentfulMatchReport
  const hasByline = page?.byline

  const Details = () => {
    if (!page?.lineUps && !page?.statistics) return null

    return (
      <React.Fragment>
        <MetaData title={page?.title} />
        <Card variant="slim">
          {page?.lineUps && (
            <Accordian collapsable heading="Line Ups" open>
              <Box p={4}>
                <LineUp
                  {...page?.lineUps}
                  clubOne={page?.clubOne}
                  clubTwo={page?.clubTwo}
                />
              </Box>
            </Accordian>
          )}
          {page?.statistics && (
            <Accordian collapsable heading="Statistics" open>
              <Box pt={3} pb={3} pl={8} pr={8}>
                <Versus
                  variant="tiny"
                  color="black"
                  home={{
                    name: page?.clubOne?.title,
                    crest: page?.clubOne?.crest?.file?.url,
                  }}
                  away={{
                    name: page?.clubTwo?.title,
                    crest: page?.clubTwo?.crest?.file?.url,
                  }}
                  sx={{ mb: 4 }}
                />
                <Statistic {...page?.statistics} />
              </Box>
            </Accordian>
          )}
        </Card>
      </React.Fragment>
    )
  }

  const parseScores = () => {
    if (page.teamOneScore === false || page.teamTwoScore === false) return false
    return { home: page.teamOneScore, away: page.teamTwoScore }
  }

  const parseHighlights = () => {
    if (!page?.teamOneHighlights && !page?.teamTwoHighlights) return false

    return [
      formatHighlights(page?.teamOneHighlights),
      formatHighlights(page?.teamTwoHighlights),
    ]
  }

  const formatHighlights = data => {
    if (!data) return []

    return data.map(x => {
      return {
        content: x.title,
        cards: formatCards(x),
      }
    })
  }

  const formatCards = data => {
    if (!data || (!data.yellowCards && !data.redCards)) return false

    const cards = []
    if (data.yellowCards) {
      let y = 0
      while (y < parseInt(data.yellowCards)) {
        cards.push('yellow')
        y++
      }
    }

    if (data.redCards) {
      let r = 0
      while (r < parseInt(data.redCards)) {
        cards.push('red')
        r++
      }
    }

    return cards
  }

  return (
    <Layout {...props}>
      <Hero
        button={{
          label: 'Match Report Archive',
          url: '/match-report',
        }}
        back="/match-report"
        data={[
          {
            type: 'Custom',
            children: (
              <Versus
                title="Match Report"
                variant="large"
                optaFixtureId={page?.optaFixtureId}
                scores={parseScores()}
                home={{
                  name: page?.clubOne?.title,
                  crest: page?.clubOne?.crest?.file?.url,
                }}
                away={{
                  name: page?.clubTwo?.title,
                  crest: page?.clubTwo?.crest?.file?.url,
                }}
                league={{
                  logo: page?.league?.logo?.file?.url,
                  name: page?.league?.title,
                }}
                highlightData={parseHighlights}
                resultData={page?.result}
                highlights
                result
              />
            ),
          },
        ]}
      />

      {hasByline && (
        <Byline
          left={page.byline}
          right={moment(page.date).format('Do MMMM YYYY')}
          sameLine
        />
      )}

      <Stack gap={4} my={10}>
        <Wrapper>
          <Inner>
            <Article
              {...page.article}
              columns={responsive(1, '1fr 1fr')}
              asideExtra={<Details />}
              forceAside={true}
            />
          </Inner>
        </Wrapper>
        {page?.promo && (
          <Wrapper>
            <Inner>
              <Promo data={page?.promo} dark={props.light} />
            </Inner>
          </Wrapper>
        )}
      </Stack>
    </Layout>
  )
}

export default MatchReport

export const pageQuery = graphql`
  query ReportBySlug($slug: String!) {
    contentfulMatchReport(slug: { eq: $slug }) {
      title
      slug
      byline
      date
      statistics {
        teamOnePossession
        teamTwoPossession
        teamOneShots
        teamTwoShots
        teamOneShotsOnTarget
        teamTwoShotsOnTarget
        teamOneCorners
        teamTwoCorners
        teamOneTackles
        teamTwoTackles
      }
      lineUps {
        teamOneCoach
        teamTwoCoach
        teamOneGoalkeeper
        teamTwoGoalkeeper
        teamOneDefenders
        teamTwoDefenders
        teamOneMidfielders
        teamTwoMidfielders
        teamOneForward
        teamTwoForward
        teamOneSubstitutes
        teamTwoSubstitutes
      }
      clubOne {
        ...ClubFragment
      }
      clubTwo {
        ...ClubFragment
      }
      teamOneScore
      teamTwoScore
      result
      teamOneHighlights {
        title
        yellowCards
        redCards
      }
      teamTwoHighlights {
        title
        yellowCards
        redCards
      }
      article {
        ... on ContentfulArticle {
          title
          main {
            raw
            references {
              ...ArticleFragment
            }
          }
          aside {
            raw
            references {
              ...ArticleFragment
            }
          }
        }
      }
      promo {
        ...PromoFragment
      }
      league {
        title
        logo {
          file {
            url
          }
        }
      }
    }
  }
`
